.slideImage {
  margin: 0 auto;
  overflow: hidden;

  img {
    height: 76px;
    width: 76px;
    margin: 0 auto;
    object-fit: contain;
  }
}

.thumbsSwiper {
  margin-top: 8px;
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;

  @media screen and (max-width: 980px) {
    border-color: transparent;
    border-radius: unset;
  }
}

.thumbsActive {
  width: 100%;
}

.thumbsSlide {
  padding: 2px;
  display: flex;
  max-width: 79px;
  height: 130px;
  margin-right: 12px;
  border-radius: 8px;
  cursor: pointer;

  @media screen and (max-width: 767px) {
    max-width: 72px;
    margin-right: 1px;
  }

  &:global(.swiper-slide-thumb-active) {
    border-color: var(--primary-color);
    background: #ffffff;
  }
}

.thumbsSlideImage {
  display: flex;
  width: 76px;
  height: 76px;

  @media screen and (max-width: 767px) {
    height: 66px;
    width: 72px;
  }

  img {
    padding: 3px;
    border-radius: 8px;
    height: 72px;
    width: 72px;
    object-fit: contain;

    @media screen and (max-width: 767px) {
      padding: 2px;
      height: 62px;
      width: 64px;
    }
  }
}
