@import '../../../../../global/styles/typography.scss';

.cardCart {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  opacity: 1;
  margin-top: 16px;
  white-space: nowrap;

  button {
    width: 128px;
    height: 40px;
    border-radius: 60px;

    @media screen and (max-width: 767px) {
      padding: 4px;
      font-size: 12px;
      width: 92px;
      height: 32px;
    }
  }
}

.deliveryInfoText {
  font-size: 13px;
  line-height: 18px;
  margin-top: 4px;
  color: #333333;

  @media screen and (max-width: 767px) {
    font-size: $smallMobileFontSize;
  }
}

.expressText {
  display: flex;
  font-size: 13px;
  line-height: 18px;
  margin-top: 4px;
  color: #333333;

  @media screen and (max-width: 767px) {
    font-size: $smallMobileFontSize;
  }
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 12px;

  button {
    width: 100%;

  }
}

.productCardCounter {
  justify-content: space-between;
  width: 100%;

  button {
    width: 40px;
  }
}

.deliveryContent {
  position: relative;
}

.deliveryText {
  position: absolute;
  color: #FFFFFF;
  font-weight: 600;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
  z-index: 1;
  font-size: 13px;

  @media screen and (max-width: 767px) {
    font-size: 12px;
  }
}

.deliveryMobile {
  display: none;

  @media screen and (max-width: 767px) {
    display: flex;
  }
}

.mobileHidden {
  display: flex;

  @media screen and (max-width: 767px) {
    display: none;
  }
}