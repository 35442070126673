.goodsSwiperBlock {
  position: relative;
  margin-left: -12px;
  margin-right: -12px;
  z-index: 20;

  @media screen and (max-width: 767px) {
    margin-top: 32px;
  }

  > div {
    @media screen and (max-width: 576px) {
      max-width: 45%;
      margin: 0;
    }
  }
}
.goodsSwiperPanel {
  position: absolute;
  left: calc((1600px - 100vw) / 2);
  top: -30px;
  background-color: #fff;
  max-width: 1600px;
  height: 200%;
  z-index: 100;

  @media screen and (max-width: 1650px) {
    display: none;
  }

  &.left {
    left: initial;
    right: calc((1600px - 100vw) / 2);
  }
}
.swiper {
  overflow: visible;
}

.slide {
  height: auto;
  display: flex;
}

@media (min-width: 576px) {
  .slide {
    width: 33.33%;
  }
}

@media (min-width: 1025px) {
  .slide {
    width: 25%;
  }
}

@media (min-width: 1441px) {
  .slide {
    width: 20%;
  }
}

.swiperNavButton {
  position: absolute;
  left: 24px;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  color: var(--primary-color, #a71db5);
  background: #fff;
  cursor: pointer;
  z-index: 20;

  @media screen and (max-width: 767px) {
    display: none;
  }
}
.swiperNavButtonNext {
  left: initial;
  right: 32px;
}
