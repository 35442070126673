.container {
  display: flex;
  flex-direction: column;
}

.label {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  color: #808080;

  @media screen and (max-width: 767px) {
    margin-top: 20px;
    font-size: 13px;
  }

  span {
    margin-left: 5px;
    color: black;
  }
}

.itemList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -10px -8px;
}

.item {
  padding: 10px 6px;
}
