@import '../../../../../global/styles/typography.scss';

.stickers {
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 11;
  width: calc(100% - 134.3px);

  @media screen and (max-width: 979px) {
    display: none;
    overflow: hidden;
  }

  & img {
    width: auto;
  }
}

.stickersNotExpress {
  width: 100%;
}

.stickersMobile {
  display: none;

  @media screen and (max-width: 979px) {
    width: calc(100% - 28px);
    height: 15px;
    object-fit: contain;
    padding-right: 4px;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 0;
    align-items: center;
    justify-content: flex-start;
    z-index: 100;
  }

  & img {
    width: auto;
  }
}

.imageStickerItem {
  object-fit: contain;
  margin-right: 8px;

  @media screen and (max-width: 979px) {
    margin-right: 4px;
  }
}

.cardStickerText {
  font-size: 14px;
  color: #808080;
  line-height: 1.1;
  white-space: nowrap;
  margin-right: 8px;

  @media screen and (max-width: 979px) {
    font-size: $smallMobileFontSize;
    margin-right: 4px;
  }
}