.goodsList {
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;
  margin-bottom: -70px;

  @media screen and (max-width: 1024px) {
    margin-bottom: -35px;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 0;
    margin-top: 32px;
  }

  > div {
    flex: 0 0 20%;
    max-width: 20%;
    margin-bottom: 68px;

    @media screen and (max-width: 1440px) {
      flex: 0 0 25%;
      max-width: 25%;
    }
    @media screen and (max-width: 1024px) {
      margin-bottom: 7px;
      flex: 0 0 33.333333333333%;
      max-width: 33.333333333333%;
    }

    @media screen and (max-width: 767px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}
