@import '../../../../../global/styles/typography.scss';

.CardUSPContainer {
  display: flex;
  align-items: center;

  @media screen and (max-width: 767px) {
    width: 160px;
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 6px;

    @media screen and (max-width: 687px) {
      width: 12px;
      height: 14px;
    }

    @media screen and (max-width: 386px) {
      width: 12px;
    }
  }

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 12px;

    @media screen and (max-width: 812px) {
      font-size: 12px;
    }

    @media screen and (max-width: 687px) {
      font-size: $smallMobileFontSize;
      line-height: 12px;
    }
  }
}