@import '../../../../../global/styles/typography.scss';

.cardSupplyDays {
  font-size: 13px;

  @media screen and (max-width: 797px) {
    font-size: $smallMobileFontSize;
  }

  span {
    line-height: 12px;
    font-weight: 500;
  }

  .boldDateText {
    font-weight: 700;
  }
}


.expressSupplyDays {
  font-weight: 700;
}