.checkoutComplete {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  width: 80%;
  max-width: 375px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  @media screen and (max-width: 767px) {
    width: 100%;
    max-width: initial;
  }
}
.checkoutCompleteIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: #006400;
  color: white;
  border-radius: 50%;
}
.checkoutCompleteTitle {
  margin: 16px 0;
  letter-spacing: -1px;
  font-size: 36px;
  font-weight: 500;

  @media screen and (max-width: 576px) {
    font-size: 20px;
  }
}
.checkoutCompleteText {
  line-height: 20px;

  @media screen and (max-width: 767px) {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}
.checkoutCompleteButton {
  margin-top: 32px;

  @media screen and (max-width: 576px) {
    width: 100%;
  }
}
