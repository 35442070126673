@import "~global/styles/variables.global.scss";

.productOptionTileWrapper {
    width: 33.333%;
    padding-right: 8px;
    margin-bottom: 8px;
}

.productOptionTile {
    border: 1px solid $light-grey;
    min-height: 32px;
    height: 100%;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: white;
    transition: background-color 0.2s;
    padding: 2px 4px;

    font-size: 13px;
    line-height: 15px;
    text-align: center;
    word-break: break-all;

    &:hover {
        background-color: rgba($light-grey, 0.25);
    }

    &.current {
        border: 2px solid $logo-color;
    }

    &.more {
        background-color: $logo-color-10;
        color: $logo-color;
        border: none;
        font-size: 12px;
    }
}
