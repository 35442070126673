@import '../../../../global/styles/typography.scss';

.variant {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  border: 2px solid #f2f2f2;
  border-radius: 8px;
  background: #ffffff;

  cursor: pointer;
  user-select: none;
  transition: border-color 0.2s;

  &:hover {
    border: 2px solid var(--primary-color);
  }

  &.variantActive {
    border: 2px solid var(--primary-color);
  }
}

.variantImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 82px;
  height: 82px;
  object-fit: contain;
  padding: 1px;
  border-radius: 8px;
}

.variantColor {
  width: 100%;
  position: absolute;
  left: 0;
  top: 87px;
  display: flex;
  justify-content: space-around;
  font-size: 12px;
  text-align: center;

  @media screen and (max-width: 767px) {
    top: 80px;
    font-size: $smallMobileFontSize;
  }
}