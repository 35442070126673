.tab {
  padding: 6px 20px 6px 20px;
  border-radius: 8px;
  border: 1px solid #EBEBEB;
  background: white;
  color: black;
  font-size: 16px;
  line-height: 140%;
}

.tabActive {
  composes: tab;
  border: 2px solid var(--primary-color);
  color: black;
  cursor: default;
}
