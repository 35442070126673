.cardContainer {
  padding: 12px;
  border: 1px solid transparent;
  border-radius: 8px;
  width: 100%;
  transition: all 100ms ease;

  @media screen and (min-width: 1024px) {
    &:hover {
      border: 1px solid #b4b2b2;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
    }
  }
}

.card {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  height: 100%;

  @media screen and (max-width: 1024px) {
    width: 100%;
    max-width: 100%;
  }
}

.cardHeader {
  @media screen and (max-width: 979px) {
    position: absolute;
    top: 4px;
    right: 8px;
  }
}

.newProposalsCardHeader {
  align-items: flex-start;
}

.cardIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  cursor: pointer;
  transition: color 150ms;
  width: 29px;
  height: 18px;
  position: absolute;
  right: 7px;
  z-index: 13;
  top: 10px;

  svg {
    fill: white;
  }

  @media screen and (max-width: 1024px) {
    top: 6px;
  }

  @media screen and (max-width: 979px) {
    top: 6px;
    width: 20px;
    right: 0;
  }

  &:hover,
  &.active {
    color: var(--primary-color, #af1ebe);
  }
  &.active svg {
    fill: var(--primary-color, #af1ebe);
  }
}

.cardImage {
  position: relative;
  text-align: center;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  a {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 50%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  svg {
    max-width: 278px;
    width: 100%;
    height: auto;
    color: #b3b3b3;
  }
}

.cardVariants {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 4px;
  margin-top: 16px;
  margin-bottom: 10px;

  @media screen and (max-width: 767px) {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(5, 1fr);
  }
}
.cardVariant {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  padding: 4px;
  background: #ffffff;
  width: 30px;
  height: 30px;
  cursor: pointer;
  user-select: none;
  transition: border-color 0.2s;

  &:hover {
    border-color: var(--primary-color-hover);
  }

  @media screen and (max-width: 768px) {
    border-radius: 6px;
    padding: 2px;
  }
}

.cardVariantImageWrapper {
  width: 30px;
  height: 30px;
  padding: 4px;
  & div {
    width: 100%;
    height: 100%;
  }
  & img{
    width: 100%;
    height: 100%;
  }
}

.variantImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 7px;
}

.moreVariants {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  background: #ffffff;

  cursor: pointer;
  user-select: none;
  transition: border-color 0.2s;

  color: var(--primary-color);
  font-size: 14px;

  &:hover {
    border-color: var(--primary-color-hover);
  }

  &.variantActive {
    border-color: var(--primary-color);
  }

  @media screen and (max-width: 1080px) {
    font-size: 14px;
  }

  @media screen and (max-width: 768px) {
    border-radius: 4px;
    padding: 2px;
  }

  @media screen and (max-width: 440px) {
    font-size: 10px;
  }
}

.cardTitle {
  margin-top: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 38px;
  min-height: 38px;
  font-size: 16px;
  font-weight: 500;
  line-height: initial;
  text-overflow: ellipsis;
  overflow: hidden;

  @media screen and (max-width: 979px) {
    font-size: 13px;
    height: 32px;
    min-height: 32px;
  }
}
.cardPriceBlock {
  display: flex;
  align-items: first baseline;
  flex-wrap: nowrap;
  margin-top: 8px;
  max-height: 32px;
  min-height: 14px;

  @media screen and (max-width: 767px) {
    margin-top: 4px;
    max-height: 20px;
    min-height: 20px;
  }
}
.cardPrice {
  font-size: 22px;
  font-weight: 700;
  white-space: nowrap;
  margin-right: 4px;

  @media screen and (max-width: 979px) {
    font-size: 16px;
  }
}

.cardPriceOld {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #A0A0A0;
  font-weight: 500;
  text-decoration: line-through;

  @media screen and (max-width: 979px) {
    font-size: 12px;
  }
}
.cardSale {
  border-radius: 8px;
  width: 55px;
  height: 28px;
  position: absolute;
  padding: 3px;
  left: 8px;
  bottom: -8px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -1px;
  color: #fff;
  background: #f1222f;
  text-align: center;
  transform: skewX(-14deg);
  display: block;

  @media screen and (max-width: 979px) {
    display: none;
  }
}
.cardSaleMobile {
  border-radius: 4px;
  padding: 1px;
  width: 36px;
  height: 16px;
  display: none;
  left: 0;
  bottom: 0px;
  font-size: 10px;

  @media screen and (max-width: 979px) {
    display: block;
  }
}

.imageWrapper {
  position: relative;
}

.cardUspWrapper {
  display: flex;
  margin-top: 8px;
  flex-direction: column;
  row-gap: 5px;
  flex-grow: 1;
}
