@import '~global/styles/modules/inline-modal.module.scss';

.favoriteModalItem {
  display: flex;
  margin-top: 32px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }

  > * + * {
    margin-left: 20px;

    @media screen and (max-width: 767px) {
      margin-left: 0;
    }
  }
}
.favoriteModalItemImage {
  position: relative;
  width: 175px;
  height: 175px;

  svg {
    width: 100%;
    height: 100%;
    color: #b3b3b3;
  }

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}
.favoriteModalItemBlock {
  flex: 1;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.favoriteModalItemInfo {
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
}
.favoriteModalItemSku {
  margin-bottom: 6px;
  font-size: 12px;
  color: #808080;

  @media screen and (max-width: 767px) {
    text-align: center;
  }
}
.favoriteModalItemTitle {
  display: block;
  margin-top: 4px;
  font-size: 20px;
  font-weight: 500;
}
.favoriteModalItemPrice {
  margin-left: auto;
  margin-top: 20px;
  font-size: 48px;
  font-weight: bold;
  letter-spacing: -1px;
  line-height: 1;

  @media screen and (max-width: 979px) {
    font-size: 32px;
  }
  @media screen and (max-width: 767px) {
    margin-left: 0;
    margin-top: 10px;
  }
}
.favoriteModalItemPriceBlock {
  margin-left: 20px;
}

.favoriteModalButtons {
  display: flex;
  margin: auto -4px 0;

  @media screen and (max-width: 590px) {
    flex-direction: column;
  }

  > * {
    width: 100%;
    margin: 32px 4px 0;
  }
  > * + * {
    @media screen and (max-width: 590px) {
      margin-top: 8px;
    }
  }
}
