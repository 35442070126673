.productOption {
  margin-top: 20px;
  margin-bottom: 32px;
  &:not(:last-child) {
    margin-bottom: 28px;

    @media screen and (max-width: 767px) {
      margin: 0;
    }
  }
}

.productOptionLabel {
  margin: 20px 0 8px 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #808080;

  @media screen and (max-width: 767px) {
    font-size: 13px;
  }
}

.productCurrentOption {
  margin-left: 5px;
  font-weight: 600;
  color: black;
}

.inputDropDown {
  width: 55%;
  border-radius: 8px;
  @media screen and (max-width: 940px) {
    width: 100%;
  }
}