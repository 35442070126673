@import '../../global/styles/variables.global.scss';

.counter {
    display: flex;
    align-items: center;
    gap: 10px;

    & .counter__button {
        background: #f1f3f4;


    }

    span {
        font-size: 16px;
        font-weight: 600;
        width: 15px;
        text-align: center;
    }


}

.counter__button_grey {
    background: #f1f3f4 !important;

    svg {
        color: $logo-color;
    }
}

.disabled {
    pointer-events: none;

    svg {
        color: #A0A0A0 !important;
    }
}